@import "src/styles/_variables";

.yellow {
  color: map-get($theme-yellow, medium);
}
.dark {
  color: map-get($theme-dark, light);
}
.light {
  color: map-get($theme-light, medium);
}
