@import "../../styles/_variables";
@import url('https://fonts.googleapis.com/css2?family=Indie+Flower&display=swap');

.postal {
  width: 77vw;
  aspect-ratio: 16/9;
  position: relative;
  display: grid;
  grid-template-columns: 49.5% 1% 49.5%;
  border: 3.5px solid;

  .timbre {
    position: absolute;
    right: 1.3em;
    top: 1.3em;

    img {
      transform: rotate(0.01turn);
      height: 10vw;
    }

    .stamp-ext {
      opacity: 1;
      transition: opacity 0.5s ease-in-out;
      height: 7vw;
      width: 8vw;
      position: absolute;
      top: 5vw;
      right: 2vw;
      display: flex;
      justify-content: center;
      align-items: center;

      .s {
        width: 7vw;
        height: auto;
      }
    }

    .hidden {
      opacity: 0;
      pointer-events: none;
      height: 0;
    }

    .visible {
      opacity: 1;
    }
  }

  .postal-title {
    display: flex;
    align-items: center;
    position: absolute;
    font-size: 2.2vw;
    top: 3vw;
    left: 3vw;

    span {
      margin-left: 0.3em;
      margin-right: 0.3em;
    }

    svg {
      height: 2.2vw;
    }
  }

  .form-left {
    font-family: 'Indie Flower', cursive;
    position: absolute;
    top: 12vw;
    height: 25vw;
    left: 3vw;
    width: 30vw;
    z-index: 10;

    textarea {
      font-size: 1.2vw;
      padding: 0.5vw;
      width: 100%;
      height: 100%;
      outline: none;
      border: none;
      background-color: transparent;
      color: inherit;

    }

  }

  .separator {
    margin-top: auto;
    margin-bottom: auto;
    height: 70%;
    border-right: 4px solid;
  }

  .form-right {
    font-family: 'Indie Flower', cursive;
    font-size: 1.2vw;
    position: absolute;
    width: 30vw;
    height: 15vw;
    top: 15vw;
    right: 3vw;

    input {
      color: inherit;
      padding-left: 1vh;
      background-color: transparent;
      width: 100%;
      height: 4vh;
      border: none;
      outline: none;
      border-bottom: 2px solid;
      margin-bottom: 1vh;
    }
  }

  .send {
    border: 2px solid;
    background-color: transparent;
    color: inherit;
    padding: 0.5em;
    cursor: pointer;
    border-radius: 10px;
    position: absolute;
    bottom: 1em;
    right: 1em;
    outline: none;
  }

  .send:hover {
    background-color: #0a5462;
    color: #e0e2e8;
  }
}

.email-send {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 50vh;
  width: 40vw;

  svg {
    height: 25vh;
    margin-bottom: 1em;
  }

  div {
    display: flex;
    text-align: center;
    font-size: 2em;
  }
}

@media screen and (max-width: 900px) {
  .postal {
    width: 65vw;
    height: 80vh;
    display: flex;

    .postal-title {
      display: flex;
      font-size: 5.2vw;
      top: 10vw;

      span {
        margin-left: 0.3em;
        margin-right: 0.3em;
      }

      svg {
        height: 2.2vw;
      }
    }

    .timbre {
      img {
        transform: rotate(0.01turn);
        height: 15vw;
      }

      .stamp-ext {
        top: 10vw;
        right: 5vw;

        .s {
          width: 14vw;
        }
      }

      .hidden {
        opacity: 0;
        pointer-events: none;
        height: 0;
      }

      .visible {
        opacity: 1;
      }
    }

    .separator {
      display: none;
    }

    .form-right {
      width: 55vw;
      height: 20vh;
      top: 15vh;
      left: 0;
      right: 0;
      margin: auto;

      input {
        color: inherit;
        font-size: 3.5vw;
        padding-left: 1vh;
        background-color: transparent;
        width: 50vw;
        height: 4vh;
        outline: none;
        border-bottom: 2px solid;
        margin-bottom: 1vh;
      }
    }

    .form-left {
      font-family: 'Indie Flower', cursive;
      position: absolute;
      top: 35vh;
      height: 35vh;
      left: 0;
      right: 0;
      margin: auto;
      width: 55vw;
      z-index: 10;
      textarea {
        background-color: rgba(255, 228, 196, 0.04);
        font-size: 3.5vw;
        padding: 0.5vw;
        width: 100%;
        height: 100%;
        outline: none;
      }
    }
  }
}
