$width: 70;
$height: calc($width / 2);
.stampMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 92vh;

  .stampBody {
    width: $width vw;
    height: $height vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .stampRight {
      display: grid;
      width: 100%;
      grid-template-rows: 20% 5% 75%;
      font-family: "Roboto", sans-serif;
      font-weight: 600;

      .stampTitle {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        font-size: 3.5vw;
        letter-spacing: 0.2em;
      }

      .stampRightDividor {
        display: flex;
        align-items: center;
        justify-content: center;

        span {
          width: 46%;
        }

        div {
          margin-left: 0.5em;
          margin-right: 0.5em;
        }
      }

      .stampName {
        display: flex;
        align-items: flex-start;
        justify-content: center;
        padding: 1em;

        .lastname {
          font-size: 16vw;
        }

        .firstname {
          font-size: 5vw;
          margin-top: 0.2em;
          writing-mode: vertical-rl;
          transform: scaleY(-1) scaleX(-1);
        }
      }
    }
  }
}
